import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const NavBar = props => {
  return (
    <nav className={`navbar is-dark ${!props.active ? "is-hidden-touch" : ""}`} role="navigation" aria-label="main navigation">
      <div className={`navbar-menu ${props.active ? "is-active" : ""}`}>
        <div className="navbar-end">
          <Link className="navbar-item" to="/">
            Accueil
          </Link>
          <div className="navbar-item has-dropdown is-hoverable">
            <Link className="navbar-link" to={`/cadres/`}>
              Cadre sur mesure
            </Link>
            <div className="navbar-dropdown">
              <Link className="navbar-item" to={`/cadres/Cadre-aluminium/`}>
                Cadre aluminium
              </Link>
              <Link className="navbar-item" to={`/cadres/Cadre-bois/`}>
                Cadre bois
              </Link>
              <Link className="navbar-item" to={`/cadres/Cadre-avec-Marie-Louise/`}>
                Cadre avec marie louise
              </Link>
              <Link className="navbar-item" to={`/cadres/Caisse-americaine/`}>
                Caisse américaine
              </Link>
              <Link className="navbar-item" to={`/cadres/Cadre-vitrine/`}>
                Cadre vitrine
              </Link>
              <Link className="navbar-item" to={`/cadres/Cadre-entre-deux-verres/`}>
                Cadre entre deux verres
              </Link>
              <Link className="navbar-item" to={`/cadres/Cadre-astral/`}>
                Cadre astral
              </Link>
            </div>
          </div>

          <Link className="navbar-item" to="/passe-partout/">
            Passe-partout
          </Link>
          <Link className="navbar-item" to="/tirage-photo-encadre/">
            Tirage photo
          </Link>
          <Link className="navbar-item" to="/accessoires/">
            Accessoires
          </Link>
          <Link className="navbar-item" to="/nous-contacter/">
            Nous contacter
          </Link>
          <div style={{ display: "flex", alignItems: "center", marginLeft: 30 }}>
            <div style={{ display: "flex", height: 40, padding: "3px 10px", background: "#aaa", alignItems: "center", borderRadius: 10 }}>
              <div style={{ display: "flex", marginRight: 8, borderRadius: "50%", width: 18, height: 18, overflow: "hidden" }}>
                <div style={{ width: 6, height: 18, background: "#2d438e" }}></div>
                <div style={{ width: 6, height: 18, background: "#fff" }}></div>
                <div style={{ width: 6, height: 18, background: "#f32220" }}></div>
              </div>
              <div style={{ fontSize: "0.7rem", fontWeight: "bold", lineHeight: "0.8rem", textAlign: "center" }}>
                Fabrication
                <br />
                Française
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

NavBar.propTypes = {
  location: PropTypes.object,
  active: PropTypes.bool,
}

export default NavBar
