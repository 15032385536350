import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

const ImgPa = ({ id, ...props }) => {
  const data = useStaticQuery(graphql`
    query imagesPa {
      allMysqlAccessoires {
        edges {
          node {
            pa_id
            mysqlImages {
              childImageSharp {
                gatsbyImageData(width: 200)
              }
            }
          }
        }
      }
    }
  `)

  let match = null
  match = useMemo(() => data.allMysqlAccessoires.edges.find(({ node }) => id === node.pa_id), [data, id])

  //console.log(id, props, match)
  return <GatsbyImage image={getImage(match.node.mysqlImages[0])} alt="passe partout" {...props} />
}
ImgPa.propTypes = {
  id: PropTypes.number,
}
export default ImgPa
