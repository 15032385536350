import { SlackOAuthClient } from "messaging-api-slack"

export const sendMessage = (title, content = []) => {
  // n'envoie les alertes que si on est sur le site, même en prod, si l'url ne match pas inutile d'envoyer
  if (process.env.NODE_ENV == "production" && typeof window !== "undefined" && window.location.href.includes("encadrement-sur-mesure.fr")) {
    const client = new SlackOAuthClient({
      accessToken: "xoxp-12970109927-12994999092-596958357282-d0b1be921f8a7171b658913cd5e2b9ef",
    })
    let url = typeof window !== "undefined" ? window.location.href : ""
    return client.callMethod("chat.postMessage", {
      channel: "alerts-cadres",
      blocks: JSON.stringify([
        {
          type: "section",
          text: {
            type: "mrkdwn",
            text: title + " " + url + " : ",
          },
        },
        ...content,
      ]),

      as_user: false,
      username: "Encadrement sur mesure",
    })
  } else {
    // eslint-disable-next-line no-undef
    return new Promise((resolve) => resolve(true))
  }
}
