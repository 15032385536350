import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby-plugin-spinner"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"

import PopupPanier from "../components/popupPanier"
import NavBar from "./navBar"
import Banner from "./banner"
import { ReactComponent as Sprite } from "../images/sprite.svg"
import { ErrorBoundary } from "react-error-boundary"

import "./all.scss"
import { logout } from "../actions"
import { resetPageProduct } from "../actions/cadre"

import Fade from "react-reveal/Fade"
import { removeAllProducts } from "../actions/panier"
import Search from "./search"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import { sendMessage } from "../services/slack"

import Obfuscate from "react-obfuscate"

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="container">
      <div role="alert">
        <div className="mt-5">Encadrement-sur-mesure.fr</div>
        <h1>Oups, une erreur est survenue</h1>
        <p>
          Veuillez nous excuser pour les désagréments. Si l&apos;erreur persiste, merci de nous contacter : <Obfuscate email="contact@producadre.fr" /> ou au{" "}
          <Obfuscate tel="03 89 06 00 93" />
        </p>
        <pre style={{ display: "none" }}>{error.message}</pre>
        <button onClick={resetErrorBoundary} data-cy="error-refresh-page">
          Recharger la page
        </button>
      </div>
    </div>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func,
}

const Layout = (props) => {
  const [navBarClass, setNavBarClass] = useState(false)
  const identifiants = useSelector((s) => s.identifiants)
  const panier = useSelector((s) => s.panierR)
  const dispatch = useDispatch()
  const toggleHamburger = () => {
    setNavBarClass(!navBarClass)
  }

  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(width: 160)
        }
      }
      avisVerifie1: file(relativePath: { eq: "avisVerifie/SceauOr.png" }) {
        childImageSharp {
          gatsbyImageData(width: 75, height: 91)
        }
      }
      avisVerifie2: file(relativePath: { eq: "avisVerifie/corner_widget_top_right.png" }) {
        childImageSharp {
          gatsbyImageData(width: 141, height: 100)
        }
      }
      avisVerifie {
        note
      }
    }
  `)

  const myErrorHandler = (error, componentStack) => {
    sendMessage("erreur sur la page ", [
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "```" + error + "```",
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "Détail : ",
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: "```" + componentStack + "```",
        },
      },
    ]).then(() => {
      dispatch(removeAllProducts({}))
      dispatch(resetPageProduct())
      if (process.env.NODE_ENV == "production" && typeof window !== "undefined" && window.location.href.includes("encadrement-sur-mesure.fr")) {
        window.location.href = "https://www.encadrement-sur-mesure.fr"
      }
    })

    console.error("Une erreur à été detectée, redirection !")
  }

  const renderSearch = () => {
    return <Search />
  }

  return (
    <>
      <ErrorBoundary onError={myErrorHandler} FallbackComponent={ErrorFallback}>
        <div className="is-hidden-mobile" style={{ zIndex: 99999, bottom: 0, left: 0, position: "fixed", transformOrigin: "100% 0% 0px", cursor: "pointer" }}>
          <div style={{ position: "relative", width: 141, height: 100 }}>
            <a
              href="https://www.avis-verifies.com/index.php?action=act_count&client=encadrement-sur-mesure.fr&widget=widget22-9732"
              rel="noreferrer"
              target="_blank"
            >
              <GatsbyImage image={getImage(data.avisVerifie2)} alt="corner" />
              <GatsbyImage image={getImage(data.avisVerifie1)} alt="4 stars" style={{ position: "absolute", top: 0, left: 0 }} />
            </a>
            <div
              style={{
                position: "absolute",
                fontFamily: "sans-serif",
                color: "rgb(0, 0, 0)",
                lineHeight: 1,
                fontWeight: "bold",
                width: 44,
                height: 18,
                fontSize: 15,
                bottom: 5,
                left: 65,
              }}
            >
              <a
                href="https://www.avis-verifies.com/index.php?action=act_count&client=encadrement-sur-mesure.fr&widget=widget22-9732"
                rel="noreferrer"
                target="_blank"
              >
                <span>{data.avisVerifie.note}</span>/<span>10</span>
              </a>
            </div>
          </div>
        </div>
        <header className="header">
          <div className="columns is-mobile is-vcentered is-variable" style={{ marginBottom: 0, justifyContent: "space-between" }}>
            <div className="is-hidden-desktop">
              <div
                className={`navbar-burger burger`}
                aria-label="menu"
                aria-expanded="false"
                data-target="mainNavbar"
                onClick={() => toggleHamburger()}
                onKeyPress={() => {}}
                tabIndex={0}
                role="button"
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </div>
            </div>
            <Link className="navbar-item logo" to="/" style={{ margin: "1rem" }}>
              <GatsbyImage image={getImage(data.logo)} alt="logo encadrement sur mesure" />
            </Link>
            <div className="is-hidden-touch is-hidden-desktop-only">{renderSearch()}</div>
            <div className="is-hidden-touch">
              <div className="left">
                <div className="has-text-weight-bold">Une question, un conseil ?</div>
                <div style={{ width: 340 }}>
                  Nous sommes à votre écoute au{" "}
                  <span className="has-text-primary">
                    <Obfuscate tel="03 89 06 00 93" />
                  </span>
                </div>
                <div className="is-size-7">du lundi au vendredi 9h-18h et samedi 9h-12h</div>
              </div>
            </div>
            <a href="https://www.producadre.fr/" className="is-hidden-touch is-hidden-tablet-only is-hidden-desktop-only is-hidden-widescreen-only">
              <svg style={{ height: 70, width: 163, position: "relative", top: 10 }}>
                <use xlinkHref="#pfc" />
              </svg>
            </a>
            <div className="column is-narrow pr-5 mr-6">
              <div className="headerLinks">
                {identifiants.identifiants !== null ? (
                  <>
                    <Link to="/" onClick={() => dispatch(logout())}>
                      <div>
                        <FontAwesomeIcon icon={faSignOutAlt} />
                        <span className="is-hidden-touch">&nbsp; Déconnexion </span>
                      </div>
                    </Link>
                    <Link to="/apps/mon-compte/">
                      <div>
                        <svg width="20" height="20">
                          <use xlinkHref="#account" />
                        </svg>
                        <span className="is-hidden-touch">Mon compte</span>
                      </div>
                    </Link>
                  </>
                ) : (
                  <Link to={`/connexion/?redirect=${props.location.pathname}`} rel="noindex follow">
                    <div>
                      <svg width="20" height="20">
                        <use xlinkHref="#account" />
                      </svg>
                      <span className="is-hidden-touch">
                        Connexion<i style={{ width: "1.5rem", display: "inline-block" }}></i>
                      </span>
                    </div>
                  </Link>
                )}

                {
                  <Link to="/panier/" className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                      <div aria-haspopup="true" aria-controls="dropdown-menu4">
                        <svg width="20" height="20">
                          <use xlinkHref="#panier" />
                        </svg>
                        <span className="is-hidden-touch">Panier </span>
                        <span>({panier.products.reduce((acc, currrent) => acc + parseInt(currrent.qty), 0)})</span>
                      </div>
                    </div>
                    <div className="dropdown-menu" id="dropdown-menu4">
                      <Fade duration={400}>
                        <div className="dropdown-content">
                          <PopupPanier />
                        </div>
                      </Fade>
                    </div>
                  </Link>
                }
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="is-hidden-desktop">{renderSearch()}</div>
          <div id="ie-banner" style={{ display: "none" }}>
            <p>
              Attention, votre navigateur n&apos;est pas à jour. Le site des Professionnels du Cadre est optimisé pour les navigateurs Google Chrome, Firefox et
              Microsoft Edge.
            </p>
          </div>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `function isIncompatible() {
              if (!navigator) return true;
              var msie = navigator.userAgent.indexOf('MSIE');
              if (msie > 0) return true;
              if (navigator.userAgent.match(/Trident.*rv:11\\./)) return true;
              return false;
            }
            if (isIncompatible()) {
              var ieWarning = document.getElementById('ie-banner');
              ieWarning.setAttribute('style', 'display: block;');
            }`,
            }}
          />

          <NavBar fixed={!props.banner} location={props.location} active={navBarClass} />
          <div className="is-hidden">
            <Sprite />
          </div>
          <div className="container is-fluid" style={{ paddingBottom: "12px" }}>
            {props.children}
          </div>
        </main>
        {!props.banner ? (
          <div className="footerReduc">
            <Banner />
          </div>
        ) : null}
        <footer className="footer">
          <div className="container mt-5">
            <div className="columns">
              <div className="column is-5 has-text-justified" style={{ fontSize: "0.9rem" }}>
                <div className="columns mb-0">
                  <div className="column is-narrow">
                    <GatsbyImage image={getImage(data.logo)} alt="logo encadrement sur mesure" />
                  </div>
                  <div className="column">
                    <p>
                      La boutique <Link to="/">encadrement sur mesure</Link> est un site des <a href="https://www.producadre.fr/">professionnels du cadre</a>,
                      pionniers de la vente de{" "}
                      <strong>
                        <Link to="/cadres/">cadres sur mesure en ligne</Link>
                      </strong>
                      .
                    </p>
                  </div>
                </div>
                <p className="mt-0">
                  Ce site optimisé par notre longue expérience de la vente en ligne vous assurera de trouver votre{" "}
                  <Link to="/cadres/Cadre-bois/">baguette d’encadrement bois</Link>, <Link to="/cadres/Cadre-aluminium/">cadre aluminium</Link>,{" "}
                  <Link to="/cadres/Cadre-avec-Marie-Louise/">cadre tableau sur mesure</Link>,{" "}
                  <Link to="/cadres/Caisse-americaine-(chassis-peinture)/">caisse américaine sur mesure</Link>,{" "}
                  <Link to="/cadres/Cadre-vitrine-(option-rehausse)/">cadre sous verre sur mesure</Link>,{" "}
                  <Link to="/tirage-photo-encadre/">cadre photo sur mesure multivue</Link> de qualité parmi un vaste choix et au meilleur prix.
                </p>
                <p>
                  Votre <Link to="/cadres/">cadre sur mesure pas cher</Link>, au même tarif qu’un cadre <Link to="/cadres/Cadre-bois-15x20/">petit format</Link>
                  , <Link to="/cadres/Cadre-bois-80x100/">grand format</Link> ou <Link to="/cadres/Cadre-bois-30x40/">moyen format</Link> standard !
                </p>
                <p>
                  Chaque <Link to="/cadres/">baguette pour cadre</Link> est assemblée dans notre atelier de Besançon. Notre atelier de découpe numérique assure
                  un rendu parfait à votre <Link to="/passe-partout/">passe partout sur mesure</Link>, pour toute <strong>taille de passe partout</strong>{" "}
                  jusqu’au format XL (150cm).
                </p>
              </div>
              <div className="column is-3" style={{ fontSize: "0.9rem" }}>
                <div className="h5 mb-5 mt-3 is-size-6">Liens rapides</div>
                <nav>
                  <ul>
                    <li>
                      <Link to="/cadres/noir/">Cadre bois noir par cher pour toile</Link>
                    </li>
                    <li>
                      <a href="https://www.destock-cadre.com/chassis-toile-standard.html">Cadre chassis bois pour toile</a>
                    </li>
                    <li>
                      <Link to="/cadres/Cadre-avec-Marie-Louise/">Cadre marie louise, cadre peinture</Link>
                    </li>
                    <li>
                      <Link to="/cadres/or/">Cadre baroque</Link>
                    </li>
                    <li>
                      <Link to="/cadres/Caisse-americaine/">Encadrement caisse américaine</Link>
                    </li>
                    <li>
                      <Link to="/passe-partout/">Passe partout cadre</Link>
                    </li>
                    <li>
                      <Link to="/cadres/40x50/">Prix encadrement tableau format 40x50</Link>
                    </li>
                    <li>
                      <Link to="/cadre-photo-noir/">Cadre photo noir</Link>
                    </li>
                    <li>
                      <Link to="/accroche-tableau/">Accroche de tableau</Link>
                    </li>
                    <li>
                      <Link to="/cadre-photo-personnalise/">Cadre pour photo personnalisé</Link>
                    </li>
                    <li>
                      <Link to="/cadre-maillot/">Encadrement maillot</Link>
                    </li>
                    <li>
                      <Link to="/baguette-encadrement/">Baguette d’encadrement pour un cadre sur-mesure</Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="column is-2" style={{ fontSize: "0.9rem" }}>
                <div className="h5 mb-3 mt-0 is-size-6">
                  Les sites des
                  <br />
                  Professionnels du Cadre
                </div>
                <ul>
                  <li>
                    <a href="https://www.producadre.fr/">producadre.fr</a>
                  </li>
                  <li>
                    <a href="https://destock-cadre.com">destock-cadre.com</a>
                  </li>
                  <li>
                    <a href="https://www.cadre-design.fr">cadre-design.fr (Nielsen)</a>
                  </li>
                  <li>
                    <a href="https://www.cadre-bois-noir.fr">cadre-bois-noir.fr</a>
                  </li>
                  <li>
                    <a href="https://www.baguette-au-metre.fr">baguette-au-metre.fr</a>
                  </li>
                  <li>
                    <a href="https://www.cadre-besancon.fr">cadre-besancon.fr</a>
                  </li>
                  <li>
                    <a href="https://www.france-cadre.fr">france-cadre.fr</a>
                  </li>
                </ul>
              </div>
              <div className="column is-2 is-size-5">
                <div className="h5 mb-5 mt-3 is-size-6">Informations</div>
                <nav>
                  <ul>
                    <li>
                      <Link to="/mentions-legales/">Mentions légales</Link>
                    </li>
                    <li>
                      <Link to="/conditions-vente/">Conditions de vente</Link>
                    </li>
                    <li>
                      <Link to="/donnees-personnelles/">Données personnelles</Link>
                    </li>
                    <li>
                      <Link to="/nous-contacter/">Nous contacter</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <hr style={{ display: "block", height: 1, border: 0, borderTop: "1px solid #888", margin: "1em 0", padding: 0 }} />
            <div style={{ fontSize: "0.8rem" }}>
              <div>
                Copyright © 2021 encadrement-sur-mesure.fr <a href="https://www.producadre.fr/">Les Professionnels du Cadre</a>
              </div>
              <div className="mt-2">
                Tout l’encadrement sur mesure : encadrement tableau sur mesure, caisse américaine sur mesure, cadre photo sur-mesure, cadre vitrine maillot,
                passe partout sur mesure, etc.
              </div>
            </div>
          </div>
        </footer>
      </ErrorBoundary>
    </>
  )
}

Layout.defaultProps = {
  banner: true,
  location: { pathname: "/" },
}

Layout.propTypes = {
  banner: PropTypes.bool,
  children: PropTypes.node,
  location: PropTypes.object,
}

export default Layout
