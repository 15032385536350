import React from "react"
import PropTypes from "prop-types"
import { setSerenite, setExpress } from "../actions/panier"
import { useDispatch, useSelector } from "react-redux"
import { optionSerenite, getRemise, pourcentageExpress } from "../services/prixBaguettes"
import { getTotalProduits } from "../services/panier"
import { useStaticQuery, graphql } from "gatsby"

export const SousTotaux = (props) => {
  const dispatch = useDispatch()
  const panier = useSelector((state) => state.panierR)
  const identifiants = useSelector((state) => state.identifiants)

  const data = useStaticQuery(graphql`
    query querySousTotaux {
      allMysqlProduitAnnexe {
        edges {
          node {
            id
            pa_id
            pa_prix_vente_ht
            pa_prix_mini
          }
        }
      }
    }
  `)
  const remise = getRemise(getTotalProduits(panier, data.allMysqlProduitAnnexe.edges))
  return (
    <table className="sousTotaux">
      <tbody>
        {panier.serenite && (
          <tr>
            <td className="tfooter">
              <strong>Option sérénité</strong>
              {props.editOptions && (
                <button className="button is-small ml-2" onClick={() => dispatch(setSerenite(false))} data-cy="serenite-supprimer">
                  Supprimer
                </button>
              )}
            </td>
            <td className="tfooter">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(optionSerenite)}{" "}
              TTC
            </td>
          </tr>
        )}
        {panier.express && (
          <tr>
            <td className="tfooter">
              <strong>Option express</strong>
              {props.editOptions && (
                <button className="button is-small ml-2" onClick={() => dispatch(setExpress(false))} data-cy="express-supprimer">
                  Supprimer
                </button>
              )}
            </td>
            <td className="tfooter">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(getTotalProduits(panier, data.allMysqlProduitAnnexe.edges) * (pourcentageExpress - 1))}{" "}
              TTC
            </td>
          </tr>
        )}
        <tr>
          <td className="tfooter">
            <strong>Sous-total</strong>
          </td>
          <td className="tfooter">
            {new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(
              (panier.serenite ? optionSerenite : 0) + getTotalProduits(panier, data.allMysqlProduitAnnexe.edges) * (panier.express ? pourcentageExpress : 1)
            )}{" "}
            TTC
          </td>
        </tr>
        {remise > 0 && (
          <tr>
            <td className="tfooter">
              <strong>Remise ({remise * 100}%)</strong>
            </td>
            <td className="tfooter">
              {new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(getTotalProduits(panier, data.allMysqlProduitAnnexe.edges) * remise)}{" "}
              TTC
            </td>
          </tr>
        )}

        <tr>
          <td className="tfooter">
            <strong>Frais de port </strong>
          </td>
          <td className="tfooter">
            {panier.deliveryAddress !== null &&
            identifiants.identifiants !== null &&
            parseInt(identifiants.identifiants.addresses.find((e) => e.id_address == panier.deliveryAddress)?.id_country) !== 8
              ? "à définir"
              : new Intl.NumberFormat("fr-FR", {
                  style: "currency",
                  currency: "EUR",
                }).format(panier.fraisPort) + " TTC"}
          </td>
        </tr>

        <tr>
          <td className="tfooter">
            <strong>Total</strong>
          </td>
          <td className="tfooter">
            <strong>
              {panier.deliveryAddress !== null &&
              (identifiants.identifiants === null ||
                typeof identifiants.identifiants.addresses.find((e) => e.id_address == panier.deliveryAddress) === "undefined" ||
                parseInt(identifiants.identifiants.addresses.find((e) => e.id_address == panier.deliveryAddress)?.id_country) !== 8)
                ? "à définir"
                : new Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(
                    (panier.serenite ? optionSerenite : 0) +
                      panier.fraisPort +
                      getTotalProduits(panier, data.allMysqlProduitAnnexe.edges) * (panier.express ? pourcentageExpress : 1) -
                      getTotalProduits(panier, data.allMysqlProduitAnnexe.edges) * remise
                  ) + " TTC"}{" "}
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
SousTotaux.defaultsProps = {
  editOptions: false,
}
SousTotaux.propTypes = {
  editOptions: PropTypes.bool,
}
