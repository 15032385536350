import { getPrixCadre } from "../profcadresprix/index-legacy"
/**
 * Ajout d'un paramètre debug pour débuguer 1 seul prix à la foix
 *
 * @param {object} product node du produit
 * @param {object} pageProduit reducer ou contenu stocké dans le panier
 * @param {object} pa produits annexes (bruts sql)
 * @param {object} photosR reducer ou contenu stocké dans le panier
 */
export const getPrix = (product, pageProduit, pa, photosR, debug = false) => {
  if (typeof product.ba_id !== "undefined") {
    return (
      getPrixCadre(
        product,
        pageProduit,
        pa.map((e) => e.node),
        photosR,
        debug
      ) * 2
    )
  } else {
    if (typeof product.pa_plaque_longueur !== "undefined") {
      // passe partout :
      return (
        getPrixCadre(
          {
            ba_id: 2353,
            bm_marge_metre: 0,
            bm_marge_precoupe: 0,
            bm_marge_coupe_assemble: 0,
            ba_prix: 0,
            ba_prix_majoration: 0,
            ba_chutes: 0,
            ba_largeur: 0,
            ba_prix_fourniture: 0,
          },
          { ...pageProduit, isorel: false, pp: { ...pageProduit.pp, pp: product } },
          pa.map((e) => e.node),
          { photos: [] },
          true
        ) * 2
      )
    } else {
      return parseFloat(product.pa_prix_vente_ht) * 1.43 * 1.2
    }
  }
}

export const getTotalProduits = (panier, pa) => {
  return panier.products.reduce(
    (acc, cur) => acc + (cur.echantillon ? cur.product.prixEchantillon : getPrix(cur.product, cur.pageProduit, pa, cur.photosR) * cur.qty),
    0
  )
}
