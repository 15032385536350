/**
 *
 * @param {object} pageProduit {doublo:{ba_talon}, largeur, hauteur, pp:{type, ouvertures, interL, interH, margeG, margeH, margeB, margeD}}
 * @param {object} baguette {ba_largeur, ba_talon}
 */
module.exports.getSizeInfos = (pageProduit, baguette = { ba_largeur: 0, ba_talon: 0 })  => {
  const pp = pageProduit.pp;
  const largeurIntra = pageProduit.largeur * pp.ouvertures[0] + pp.interL * (pp.ouvertures[0] - 1) + (["simple", "multivues"].includes(pp.type) ? pp.margeG + pp.margeD : 0);
  const hauteurIntra = pageProduit.hauteur * pp.ouvertures[1] + pp.interH * (pp.ouvertures[1] - 1) + (["simple", "multivues"].includes(pp.type) ? pp.margeH + pp.margeB : 0);
  const recouvrement = Number(baguette.ba_largeur) - Number(baguette.ba_talon);
  const fullWidthCm = largeurIntra + 2 * Number(baguette.ba_talon) + (pageProduit.doublo ? pageProduit.doublo.ba_talon * 2 : 0);
  const fullHeightCm = hauteurIntra + 2 * Number(baguette.ba_talon) + (pageProduit.doublo ? pageProduit.doublo.ba_talon * 2 : 0);

  return {
    fullPerimetre: 2 * fullWidthCm + 2 * fullHeightCm, // PERIMETRE EXTERIEUR
    fullWidthCm, // LARGEUR EXT
    fullHeightCm, // HAUTEUR EXT
    perimetreIntra: 2 * largeurIntra + 2 * hauteurIntra, // Périmètre intérieur
    largeurIntra, // Largeur intérieure (avec passe)
    hauteurIntra, // Largeur intérieure (avec passe)
    // Périmètre intermédiaire (de la baguette intérieure si doublo)
    perimetreInter: 2 * (largeurIntra + (pageProduit.doublo ? pageProduit.doublo.ba_talon * 2 : 0)) + 2 * (hauteurIntra + (pageProduit.doublo ? pageProduit.doublo.ba_talon * 2 : 0)),
    recouvrement, // recouvrement de la baguette doublo
    largeurBaguettes: Number(baguette.ba_talon) + (pageProduit.doublo ? pageProduit.doublo.ba_talon : 0), // Largeur des baguettes si deux ou de la si une
    surfaceIntra: largeurIntra * hauteurIntra,
  };
};

const emballage = 1.33;
const prixSupplGrandeTailleHT = 10.73;
const idPlexiNormal = 8;
const idPlexiAntiReflet = 6;
const idVerreNormal = 2;
const idVerreAntiReflet = 3;
const idVerreInvisible = 4;
const idIsorel = 1;
const idCartonBarriere = 264;
const idDoubleVerreNormal = 358;
const idDoubleVerreInvisible = 359;
module.exports.tva = 1.2; // 20%
const doublo_ml_ht = 6 / 1.2; // 6€ le mètre
const doublo_min = 10 / 1.2; // minimum 10€ ttc

module.exports.kitMontage = 9;
module.exports.degressifDefautCadre = { 1: 0, 3: 5, 5: 10, 10: 15, 25: 20 };

module.exports.woodColors = [
  { title: "Noyer", id: [1914, 1655, 1656, 1911, 1939] },
  { title: "Gris blanchi", id: [1915] },
  { title: "Frêne", id: [1916, 1503, 1500, 1290, 1494] },
  { title: "Chêne blanchi", id: [1917] },
  { title: "Chêne doré", id: [1907] },
  { title: "Gris foncé", id: [1918] },
  { title: "Wengé foncé", id: [1909, 1940] },
  { title: "Noir ébène", id: [1910] },
  { title: "Bouleau", id: [1937] },
  { title: "Chêne", id: [1919, 1505, 1501, 1291, 1496, 1938] },
  { title: "Chêne teinté noir", id: [1920, 1504, 1502, 1463, 1495, 1941] },
];

module.exports.rehausses = {
  none: {
    title: "Aucune",
    prix: 0,
    croquis: null,
  },
  h20mm: {
    title: "Réhausse hauteur de 20mm",
    prix: 7 / 1.2 / 1.43,
    croquis: "92928",
  },
  h30mm: {
    title: "Réhausse hauteur de 30mm",
    prix: 7.8 / 1.2 / 1.43,
    croquis: "93224",
  },
};

// NE PAS MODIFIER L'ORDRE ICI !
module.exports.couleurs_rehausse = {
  naturel: {
    title: "Naturel, sans vernis, pouvant être peint",
    color: "#b5b39e",
    ba_id_img: 1291,
  },
  or: {
    title: "Or",
    color: "#FFD700",
    ba_id_img: 2027,
  },
  argent: {
    title: "Argent",
    color: "#bdbdbd",
    ba_id_img: 1793,
  },
  noir: {
    title: "Noir",
    color: "#000",
  },
  blanc: {
    title: "Blanc",
    color: "#FFF",
  },
};

module.exports.getPrixAchatMajore = (product) => product ? (product.ba_prix * (1 + product.ba_prix_majoration / 100)) : 0;

// ba_continue == 5 : commande directe fournisseur, les baguettes sont déjà précoupées par le fournisseur donc on ne rajoute pas 8*largeur baguette (coins) au périmètre
module.exports.addChutes = (product, perimetre) => (perimetre + (8 * (!product || (product && product.ba_continue == 5) ? 0 : product.ba_largeur)) / 100) * (1 + (product ? product.ba_chutes : 0) / 100);

module.exports.getPrixCoupeAssemble = (prix_revient_ht_precoupe, ba_marge_sur_mesure) => prix_revient_ht_precoupe * ba_marge_sur_mesure;

/**
 *
 * @param {*} productAnnexe
 * @param {*} hauteur
 * @param {*} largeur
 * @param {bool} revient permet de savoir si on returne le prix de vente ou revient
 */
module.exports.getPrixOption = (productAnnexe, hauteur, largeur, reduction = false, revient = false) => {
  if (revient) return productAnnexe.pa_prix_achat_majoration * this.get_surface(hauteur, largeur);
  let price = productAnnexe.pa_prix_vente_ht * this.get_surface(hauteur, largeur);
  price = price * (reduction ? 0.75 : 1);
  if (price < productAnnexe.pa_prix_mini) {
    price = productAnnexe.pa_prix_mini;
  }
  return (price * 1.43 * 1.2) / 2;
};

module.exports.get_surface = (hauteur_cadre, largeur_cadre) => (hauteur_cadre * largeur_cadre) / 10000;

/**
 * RETOURNE LE PRIX FRANCE-CADRE pour avoir le prix ESM ou autre faire X2
 * @param {object} baguetteExt produit correpondant à pageProduit.produit
 * @param {object} pageProduit en provenance de redux ou sauvegarde panier
 * @param {array} pa liste des produits annexes
 * @param {object} photosR en provenance de redux ou sauvegarde panier
 */
module.exports.getPrixCadre = (baguetteExt, pageProduit, pa, photosR, debug_ = false, quantity = 1) => {
  const debug = debug_ && process.env.NODE_ENV !== "production";
  pa = pa.map((e) => ({
    ...e,
    pa_id: parseInt(e.pa_id),
    pa_marge: parseFloat(e.pa_marge),
    pa_prix_mini: parseFloat(e.pa_prix_mini),
    pa_prix_vente_ht: parseFloat(e.pa_prix_vente_ht)
  }));

  debug && console.log("");
  debug && console.log("==> Calcul de prix : ", baguetteExt, pageProduit, pa);
  const baguetteInt = pageProduit.doublo;

  if (baguetteExt.cat_id === 16 || baguetteExt.cat_id_originale === 16) {
    // Si on est sur une entre deux verres on ajoute 5cm de chaque coté :
    pageProduit = {...pageProduit}; // copie de l'objet car on ne doit pas modifier directement le state
    pageProduit.hauteur += 10;
    pageProduit.largeur += 10;
  }

  let h = pageProduit.hauteur;
  let l = pageProduit.largeur;
  const {
    perimetreIntra,
    perimetreInter,
    hauteurIntra,
    largeurIntra,
    surfaceIntra
  } = this.getSizeInfos(pageProduit, baguetteExt);
  const extHtPrecoupe = this.addChutes(baguetteExt, perimetreInter / 100) * this.getPrixAchatMajore(baguetteExt);
  const _emballage = baguetteExt.ba_prix !== 0 ? emballage : 0;

  // Gestion des prix de revient :
  if (typeof pageProduit.revient !== "undefined") {
    if (pageProduit.service == "precoupe") return extHtPrecoupe + (typeof baguetteExt.ma_id !== "undefined" && baguetteExt.ma_id === 11 ? baguetteExt.ba_prix_fourniture : 0) + (pageProduit.rehausse != null ? (this.rehausses[pageProduit.rehausse].prix * (h * 2 + l * 2)) / 100 : 0);
    if (pageProduit.service == "metre") return 1.5 * this.getPrixAchatMajore(baguetteExt);
    if (pageProduit.service == "reference") return this.getPrixAchatMajore(baguetteExt);
  }

  if (pageProduit.service == "precoupe") return _emballage + extHtPrecoupe * baguetteExt.bm_marge_precoupe + (!pageProduit.kitMontage ? 0 : this.kitMontage) + (pageProduit.rehausse != null ? (this.rehausses[pageProduit.rehausse].prix * (h * 2 + l * 2)) / 100 : 0);
  if (pageProduit.service == "metre") return _emballage + 1.5 * this.getPrixAchatMajore(baguetteExt) * baguetteExt.bm_marge_metre + (!pageProduit.kitMontage ? 0 : this.kitMontage);
  if (pageProduit.service == "reference") return this.getPrixAchatMajore(baguetteExt) * baguetteExt.bm_marge_metre + (!pageProduit.kitMontage ? 0 : this.kitMontage);

  let prixSansOption = this.getPrixCoupeAssemble(extHtPrecoupe + baguetteExt.ba_prix_fourniture, baguetteExt.bm_marge_coupe_assemble) + _emballage;
  let revient = extHtPrecoupe + baguetteExt.ba_prix_fourniture;
  debug && console.log("1. Périmètre (cm): ", perimetreInter, "Périmètre + chutes (m) : ", this.addChutes(baguetteExt, perimetreInter / 100), "Px achat majoré", this.getPrixAchatMajore(baguetteExt), " Revient : ", revient);
  debug && console.log("2.1. Prix pro ht : ", this.getPrixCoupeAssemble(extHtPrecoupe + baguetteExt.ba_prix_fourniture, baguetteExt.bm_marge_coupe_assemble) + _emballage);

  // Cas du doublo :
  let optionDoublo = 0;
  if (baguetteInt) {
    let prixDoublo = ((l * 2 + h * 2) / 100) * doublo_ml_ht;
    const intHtPrecoupe = this.addChutes(baguetteInt, perimetreIntra / 100) * this.getPrixAchatMajore(baguetteInt);
    prixSansOption += this.getPrixCoupeAssemble(intHtPrecoupe + baguetteInt.ba_prix_fourniture, baguetteInt.bm_marge_coupe_assemble);
    revient += intHtPrecoupe + baguetteInt.ba_prix_fourniture;
    debug && console.log("2.2. prix baguette int ", this.getPrixCoupeAssemble(intHtPrecoupe + baguetteInt.ba_prix_fourniture, baguetteInt.bm_marge_coupe_assemble));

    // Ajout tarif doublo (avec minimum) :
    optionDoublo = prixDoublo < doublo_min ? doublo_min : prixDoublo;
    // L'isorel est moins cher (on en enleve sous la baguette intérieure) dans le cas du doublo !
  }

  let prix_isorel =
    pageProduit.isorel || (photosR.tirage && Object.values(photosR.photos).length >= 1)
      ? this.getPrixOption(
          pa.find((u) => u.pa_id === idIsorel),
          hauteurIntra,
          largeurIntra,
          true,
          typeof pageProduit.revient !== "undefined"
        )
      : 0;

  let supplementGrandeTaille = 0;
  if (prixSansOption) {
    // pas de supplément grande taille si passe seul - si baguette ici le prix sera > 0
    
    supplementGrandeTaille = perimetreInter >= 280 ? prixSupplGrandeTailleHT : 0;
    
    // pourquoi on faisait ça (en dessous) ? pas de raison de facturer 2 fois le supplément...
    //supplementGrandeTaille += baguetteInt !== null ? (h * 2 + l * 2 >= 280 ? prixSupplGrandeTailleHT : 0) : 0;
    
    prixSansOption += supplementGrandeTaille / quantity;
  }
  debug && console.log("3. total baguettes: ", prixSansOption, "suppl : ", supplementGrandeTaille);
  debug && console.log("4. isorel", prix_isorel);

  // Carton barrière :
  prixSansOption += !pageProduit.cartonBarriere
    ? 0
    : this.getPrixOption(
        pa.find((u) => u.pa_id === idCartonBarriere),
        largeurIntra,
        hauteurIntra,
        false,
        typeof pageProduit.revient !== "undefined"
      );
  // rehausse:
  prixSansOption += pageProduit.rehausse != null ? (this.rehausses[pageProduit.rehausse].prix * (h * 2 + l * 2)) / 100 : 0;
  // Option passe partout :
  if (["multivues", "simple"].includes(pageProduit.pp.type)) {
    const pp = pageProduit.pp;
    let pa_pp = null;
    if (pp.pp === null) {
      // pp par défaut définit dans les requêtes gatsby config
      pa_pp = pa.find((u) => u.pa_id === (largeurIntra < 80 && hauteurIntra < 120 ? 175 : 243));
    } else pa_pp = pp.pp;

    // gestion des passes grande taille :
    if (pp.pp && (Math.min(hauteurIntra, largeurIntra) > Math.min(pp.pp.pa_plaque_longueur, pp.pp.pa_plaque_largeur) || Math.max(hauteurIntra, largeurIntra) > Math.max(pp.pp.pa_plaque_longueur, pp.pp.pa_plaque_largeur))) {
      if (pageProduit.pp.pp.gm) {
        pa_pp = pageProduit.pp.pp.gm;
      }
    }

    let prixPp = this.getPrixOption(pa_pp, largeurIntra, hauteurIntra, false, typeof pageProduit.revient !== "undefined");
    prixSansOption += prixPp;
    debug && console.log("5. Option pp (" + largeurIntra + ", " + hauteurIntra + "),  :", prixPp, pa_pp.pa_prix_vente_ht);
  }

  if (pageProduit.pp.ouvertures[0] * pageProduit.pp.ouvertures[1] >= 2) {
    // On fait payer les fenêtres supplémentaires
    let pa11 = pa.find((u) => u.pa_id === 11);
    if (pa11) {
      let prixFenetres = (pageProduit.pp.ouvertures[0] * pageProduit.pp.ouvertures[1] * pa11[typeof pageProduit.revient !== "undefined" ? "pa_prix_achat_majoration" : "pa_prix_vente_ht"] * 1.43 * 1.2) / 2;
      prixSansOption += prixFenetres;
      debug && console.log("5.1 Fenetres supplémentaires :", prixFenetres);
    } else {
      debug && console.log("5.1 Pas trouvé le prix de fenetres supplémentaires");
    }
  }

  // Gestion des tirages :
  let prixTirage = 0;

  if (Object.values(photosR.photos).length >= 1) {
    let nbPhotos = pageProduit.pp.ouvertures[0] * pageProduit.pp.ouvertures[1];
    switch (photosR.tirage) {
      case "tirage":
        prixTirage = this.getPrixOption(
          pa.find((u) => u.pa_id === 16),
          pageProduit.hauteur,
          pageProduit.largeur,
          false,
          typeof pageProduit.revient !== "undefined"
        );
        break;
      case "tirage_haut_de_gamme":
        prixTirage = this.getPrixOption(
          pa.find((u) => u.pa_id === 352),
          pageProduit.hauteur,
          pageProduit.largeur,
          false,
          typeof pageProduit.revient !== "undefined"
        );
        break;
      case "tirage_sublimation":
        prixTirage = this.getPrixOption(
          pa.find((u) => u.pa_id === 353),
          pageProduit.hauteur,
          pageProduit.largeur,
          false,
          typeof pageProduit.revient !== "undefined"
        );
        break;
    }
    prixTirage *= nbPhotos;
    debug && console.log("6.1 Tirage photo : ", prixTirage);
  }

  if ((Object.values(photosR.photos).length >= 1 && typeof photosR.miseenplace === "undefined") || (typeof photosR.miseenplace !== "undefined" && photosR.miseenplace)) {
    // Mise en place tirage
    let mepTirage = 0;
    mepTirage = this.getPrixOption(
      pa.find((u) => u.pa_id === (typeof photosR.miseenplace !== "undefined" ? photosR.miseenplace : ["double_verre_normal", "double_verre_invisible"].includes(pageProduit.protection) ? 266 : ["verre_normal", "verre_invisible", "verre_anti_reflet"].includes(pageProduit.protection) ? 25 : 265)),
      pageProduit.hauteur,
      pageProduit.largeur,
      false,
      typeof pageProduit.revient !== "undefined"
    );
    debug && console.log("6.2 mise en place : unit:", mepTirage, " total:", mepTirage * pageProduit.pp.ouvertures[0] * pageProduit.pp.ouvertures[1]);
    prixTirage += mepTirage * pageProduit.pp.ouvertures[0] * pageProduit.pp.ouvertures[1];
  }
  debug && console.log("7. Protection : ", pageProduit.protection, "Prix sans options : ", prixSansOption, " Prix isorel :", prix_isorel, " Prix tirage : ", prixTirage, optionDoublo);

  let coeffReduction = 1;

  if (quantity > 2) coeffReduction = 0.95;
  if (quantity > 4) coeffReduction = 0.95 * 0.95;
  if (quantity > 9) coeffReduction = 0.95 * 0.95 * 0.95;
  if (quantity > 24) coeffReduction = 0.95 * 0.95 * 0.95 * 0.95;

  if (pageProduit)
    switch (pageProduit.protection) {
      case "plexi_normal":
        return (
          (typeof pageProduit.revient !== "undefined" ? revient : prixSansOption) +
          prix_isorel +
          prixTirage +
          this.getPrixOption(
            pa.find((u) => u.pa_id === idPlexiNormal),
            hauteurIntra,
            largeurIntra,
            true,
            typeof pageProduit.revient !== "undefined"
          ) +
          optionDoublo * this.tva
        ) * coeffReduction;
      case "plexi_anti_reflet":
        return (
          (typeof pageProduit.revient !== "undefined" ? revient : prixSansOption) +
          prix_isorel +
          prixTirage +
          this.getPrixOption(
            pa.find((u) => u.pa_id === idPlexiAntiReflet),
            hauteurIntra,
            largeurIntra,
            true,
            typeof pageProduit.revient !== "undefined"
          ) +
          optionDoublo * this.tva
        ) * coeffReduction;
      case "verre_normal":
        return (
          (typeof pageProduit.revient !== "undefined" ? revient : prixSansOption) +
          prix_isorel +
          prixTirage +
          this.getPrixOption(
            pa.find((u) => u.pa_id === idVerreNormal),
            hauteurIntra,
            largeurIntra,
            true,
            typeof pageProduit.revient !== "undefined"
          ) +
          optionDoublo * this.tva
        ) * coeffReduction;
      case "verre_anti_reflet":
        return (
          (typeof pageProduit.revient !== "undefined" ? revient : prixSansOption) +
          prix_isorel +
          prixTirage +
          this.getPrixOption(
            pa.find((u) => u.pa_id === idVerreAntiReflet),
            hauteurIntra,
            largeurIntra,
            true,
            typeof pageProduit.revient !== "undefined"
          ) +
          optionDoublo * this.tva
        ) * coeffReduction;
      case "verre_invisible":
        return (
          (typeof pageProduit.revient !== "undefined" ? revient : prixSansOption) +
          prix_isorel +
          prixTirage +
          this.getPrixOption(
            pa.find((u) => u.pa_id === idVerreInvisible),
            hauteurIntra,
            largeurIntra,
            true,
            typeof pageProduit.revient !== "undefined"
          ) +
          optionDoublo * this.tva
        ) * coeffReduction;
      case "double_verre_normal":
        return (
          (typeof pageProduit.revient !== "undefined" ? revient : prixSansOption) +
          prixTirage +
          this.getPrixOption(
            pa.find((u) => u.pa_id === idDoubleVerreNormal),
            hauteurIntra,
            largeurIntra,
            true,
            typeof pageProduit.revient !== "undefined"
          ) +
          optionDoublo * this.tva
        ) * coeffReduction;
      case "double_verre_invisible":
        return (
          (typeof pageProduit.revient !== "undefined" ? revient : prixSansOption) +
          prixTirage +
          this.getPrixOption(
            pa.find((u) => u.pa_id === idDoubleVerreInvisible),
            hauteurIntra,
            largeurIntra,
            true,
            typeof pageProduit.revient !== "undefined"
          ) +
          optionDoublo * this.tva
        ) * coeffReduction;
      default:
      case "aucune":
        return ((typeof pageProduit.revient !== "undefined" ? revient : prixSansOption) + prix_isorel + prixTirage + optionDoublo * this.tva) * coeffReduction;
    }
};
