import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons"

const Banner = () => {
  const images = useStaticQuery(graphql`
    query {
      frcadre: file(relativePath: { eq: "france-cadre-logo.png" }) {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(height: 35, width: 100, quality: 95)
        }
      }
    }
  `)
  return (
    <div className="container banner">
      <div className="columns ">
        <div className="column has-text-centered has-text-black">
          <div className="has-text-primary has-text-weight-bold">Découvrez notre site</div>
          <a href="https://france-cadre.fr/" style={{ marginTop: -4, display: "block" }}>
            <FontAwesomeIcon icon={faHandPointRight} style={{ position: "relative", top: -7, color: "#9a3c85" }} />
            <GatsbyImage image={getImage(images.frcadre)} alt="France Cadre, l'encadrement pour les professionnels" />
          </a>
          <div className="has-text-primary is-size-7" style={{ marginTop: -6 }}>
            Réservé aux professionnels
          </div>
        </div>
        <div className="column has-text-centered has-text-black">
          <div className="columns is-vcentered is-gapless is-centered">
            <div className="column is-narrow is-hidden-tablet-only is-hidden-mobile">
              <svg width="40" className="mr-3">
                <use xlinkHref="#livreur" />
              </svg>
            </div>
            <div className="column is-narrow is-size-4 has-text-weight-bold" style={{ lineHeight: "1em" }}>
              14,95€
              <div className="is-size-6">TTC</div>
            </div>
          </div>
          <div className="has-text-primary" style={{ marginTop: -4 }}>
            Port et emballage
          </div>
        </div>

        <div className="column has-text-centered has-text-black">
          <div className="columns is-vcentered is-gapless is-centered">
            <div className="column is-narrow is-hidden-tablet-only is-hidden-mobile">
              <svg width="40" height="40" className="mr-3">
                <use xlinkHref="#remise" />
              </svg>
            </div>
            <div className="column is-narrow has-text-weight-bold is-size-3" style={{ lineHeight: "0.6em" }}>
              10%
              <br /> <span className="is-size-6">de réduction</span>
            </div>
          </div>
          <div className="has-text-primary" style={{ marginTop: -4 }}>
            à partir de <b>250€</b> d&apos;achats
          </div>
        </div>

        <div className="column has-text-centered has-text-black">
          <div className="columns is-vcentered is-gapless is-centered">
            <div className="column is-narrow is-hidden-tablet-only is-hidden-mobile">
              <svg width="40" height="40" className="mr-3">
                <use xlinkHref="#remise" />
              </svg>
            </div>
            <div className="column is-narrow has-text-weight-bold is-size-3" style={{ lineHeight: "0.6em" }}>
              20%
              <br /> <span className="is-size-6">de réduction</span>
            </div>
          </div>
          <div className="has-text-primary" style={{ marginTop: -4 }}>
            à partir de <b>450€</b> d&apos;achats
          </div>
        </div>

        <div className="column has-text-centered has-text-black">
          <div className="columns is-vcentered is-gapless is-centered">
            <div className="column is-narrow is-hidden-tablet-only is-hidden-mobile">
              <svg width="40" height="40" className="mr-3">
                <use xlinkHref="#48h" />
              </svg>
            </div>
            <div className="column is-narrow has-text-weight-bold is-size-7" style={{ lineHeight: "1.5em" }}>
              Délais de traitement
              <br /> <span className="is-size-6">8 jours ouvrés</span>
            </div>
          </div>
          <div className="has-text-primary" style={{ marginTop: -4 }}>
            Option EXPRESS : 1 jour ouvré
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
