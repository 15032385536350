import React, { useState } from "react"

import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby-plugin-spinner"
import PropTypes from "prop-types"
import { Index } from "elasticlunr"
import slugify from "slugify"
import { setDimensionsDefault } from "../actions/cadre"
import { useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

export const Search = () => {
  const [query, setQuery] = useState("")
  const [results, setResults] = useState([])
  const dispatch = useDispatch()
  const data = useStaticQuery(graphql`
    query SearchIndexExampleQuery {
      siteSearchIndex {
        index
      }
    }
  `)
  const index = Index.load(data.siteSearchIndex.index)

  const search = (evt) => {
    if (query.length === 0) {
      dispatch(setDimensionsDefault())
    }
    setQuery(evt.target.value)
    setResults(
      index
        .search(evt.target.value, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => index.documentStore.getDoc(ref))
    )
  }

  return (
    <div>
      <div className="field has-addons recherche is-justify-content-center">
        <div className="control control-input mt-3">
          <input className="input recherche" value={query} onChange={(e) => search(e)} placeholder="Recherchez une référence ou une famille" />
        </div>
        <div className="control control-button mt-3 mr-3" style={{ flexBasis: "auto" }}>
          <button className="button is-primary" type="submit" data-cy="submit-search">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>
      {query !== "" ? (
        <ul className={`search over`}>
          {results.length === 0 ? <li>Aucun résultat</li> : null}
          {results.map((page, k) => (
            <li key={k}>
              <div className="search_title">
                <Link to={`/cadre/${slugify(`${page.cat_libelle}-${page.fa_libelle}-${page.co_libelle}-${page.ba_id}`)}/`}>
                  {page.fa_libelle} {page.ba_couleur_precise || page.co_libelle}
                </Link>
              </div>
              <div className="search_buttons">
                {page.baguettes_doublo
                  ? page.baguettes_doublo.map((e, key2) => (
                      <Link key={key2} to={`/cadre/${slugify(`${e.cat_libelle}-${page.fa_libelle}-${page.co_libelle}-${page.ba_id}`)}/`}>
                        <button className="button ml-1 is-small" data-cy="search-decouvrir-doublo">
                          Découvrir en version doublo {e.cat_libelle.replace("Cadre doublo", "")}
                        </button>
                      </Link>
                    ))
                  : ""}
              </div>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}

Search.propTypes = {
  data: PropTypes.object,
  dispatch: PropTypes.func,
}

export default Search
