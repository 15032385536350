import React from "react"
import PropTypes from "prop-types"

import { useDispatch, useSelector } from "react-redux"
import { removeProduct } from "../actions/panier"
import { graphql, useStaticQuery } from "gatsby"

import { PrevisuPrecoupe, PrevisuCadreComplet, PrevisuPasseSeul } from "@bko/previsus"
import { getPrix } from "../services/panier"
import ImgPa from "./img/imgPa"
import { getProtection } from "@bko/optionsproduits"
import { SousTotaux } from "./sousTotaux"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const PopupPanier = () => {
  const dispatch = useDispatch()
  const panier = useSelector((state) => state.panierR)

  const data = useStaticQuery(graphql`
    query dataPopup {
      allMysqlProduitAnnexe {
        edges {
          node {
            id
            pa_id
            pa_prix_vente_ht
            pa_prix_mini
          }
        }
      }
      astralFace: file(relativePath: { eq: "astral.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  const handleRemove = (_id) => {
    dispatch(removeProduct(_id))
  }
  return (
    <>
      {panier.products.length >= 1
        ? panier.products.map((ligne) => (
            <div key={ligne._id} className="has-text-weight-normal">
              <div className="columns">
                <div className="column is-narrow mr-2" style={{ width: 200 }}>
                  {typeof ligne.product.pa_plaque_longueur !== "undefined" ? ( // passe partout
                    <PrevisuPasseSeul product={ligne.product} pageProduit={ligne.pageProduit} echantillon={ligne.echantillon} size={150} />
                  ) : typeof ligne.product.pat_id !== "undefined" ? ( // accessoire
                    <ImgPa style={{ width: 200, height: 200 }} id={ligne.product.pa_id} />
                  ) : ligne.pageProduit.service == "echantillon" ? (
                    <GatsbyImage
                      className="couleurShadow"
                      style={{ height: "70%", width: "70%", margin: "0 2rem" }}
                      alt={"échantillon"}
                      id={ligne.product.imgCoin}
                    />
                  ) : ligne.pageProduit.service === "precoupe" ? (
                    <PrevisuPrecoupe
                      largeurFixe={150}
                      largeurCadre={ligne.pageProduit.largeur}
                      hauteurCadre={ligne.pageProduit.hauteur}
                      imageComponent={GatsbyImage}
                      baguette={{ ...ligne.product, imgLongueur: getImage(ligne.product.imgLongueur) }}
                      backgroundPouBlanc={ligne.product.cog_id === 3 ? "#9F8F7E" : "white"}
                    />
                  ) : ligne.product.cat_id === 17 ? (
                    <GatsbyImage style={{ width: 200, height: 200 }} image={getImage(data.astralFace)} alt="Cadre astral" />
                  ) : (
                    <PrevisuCadreComplet
                      product={{ ...ligne.product, imgLongueur: getImage(ligne.product.imgLongueur) }}
                      imageComponent={GatsbyImage}
                      largeurFixe={200}
                      hauteurFixe={200}
                      pageProduit={{
                        ...ligne.pageProduit,
                        doublo:
                          !ligne.pageProduit.doublo
                            ? null
                            : { ...ligne.pageProduit.doublo, imgLongueur: getImage(ligne.pageProduit.doublo.imgLongueur) },
                      }}
                      photos={ligne.photosR.photos}
                    />
                  )}
                </div>
                <div className="column">
                  <div style={{ float: "right" }}>
                    <button className="delete" onClick={() => handleRemove(ligne._id)} data-cy="popup-panier-delete"></button>
                  </div>
                  <div>
                    <b>Description : </b>
                    <br />
                    <div className="description">
                      {typeof ligne.product.ba_id !== "undefined" ? (
                        <>
                          Cadre : <span>{ligne.product.fa_libelle}</span>
                          <br />
                          Dimensions sujet :{" "}
                          <span>
                            {ligne.pageProduit.largeur}cm x {ligne.pageProduit.hauteur}cm
                          </span>
                          <br />
                          Protection :{" "}
                          <span>
                            {ligne.pageProduit.protection === null
                              ? "-"
                              : getProtection({ ...ligne.product, cat_id: ligne.product.cat_id_originale }, ligne.pageProduit, ligne.photosR)[
                                  ligne.pageProduit.protection
                                ].title}
                          </span>
                          <br />
                          Doublo : <span>{ligne.pageProduit.doublo ? "Oui" : "Non"}</span>
                          <br />
                          Couleur : <span>{ligne.product.cog_libelle}</span>
                          <br />
                          Isorel : <span>{ligne.pageProduit.isorel === null ? "-" : ligne.pageProduit.isorel ? "Oui" : "Non"}</span>
                          <br />
                          Passe partout :{" "}
                          <span style={{ textTransform: ligne.pageProduit.pp.type === "simple" ? "none" : "capitalize" }}>
                            {ligne.pageProduit.pp.type === null ? "-" : ligne.pageProduit.pp.type === "simple" ? "Une ouverture" : ligne.pageProduit.pp.type}
                          </span>
                          <br />
                          Quantité : <span>{ligne.qty}</span>
                          <br />
                          Prix unitaire :{" "}
                          <span>
                            {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
                              getPrix(ligne.product, ligne.pageProduit, data.allMysqlProduitAnnexe.edges, ligne.photosR)
                            )}
                          </span>
                        </>
                      ) : typeof ligne.product.pa_plaque_longueur !== "undefined" ? (
                        <>
                          Réf. {ligne.product.pa_reference} <br />
                          {ligne.echantillon ? (
                            <>
                              <br />
                              Echantillon
                            </>
                          ) : (
                            <>
                              Dimensions de la fenêtre image : {ligne.pageProduit.largeur}x{ligne.pageProduit.hauteur}cm <br />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <b>Ref: {ligne.product.pa_reference}</b> {ligne.product.pa_libelle}
                          <br />
                          <b>
                            Prix :{" "}
                            {new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
                              getPrix(ligne.product, ligne.pageProduit, data.allMysqlProduitAnnexe.edges)
                            )}{" "}
                            TTC x {ligne.qty}
                          </b>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : ""}

      {panier.products.length >= 1 ? (
        <div className="has-text-right">
          <div className="popupPanier mt-3 mb-3" style={{ display: "flex", flexDirection: "row-reverse" }}>
            <SousTotaux editOptions={false} />
          </div>
          Commander
        </div>
      ) : (
        <p>Votre panier est vide</p>
      )}
    </>
  )
}

PopupPanier.propTypes = {
  location: PropTypes.object,
}

export default PopupPanier
